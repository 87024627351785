import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import DateTimeFormatter from "../../features/app/DateTimeFormatter";


export function createTimeCell<T extends GridValidRowModel>(
  isSmallScreen: boolean, formatter: DateTimeFormatter, valueGetter: (row: T) => number): Partial<GridColDef<T>> {
  return {
    valueGetter: (params) => {
      const value = valueGetter(params.row);
      return new FormattedTimeValue(formatter.formatDateAndTime(value), value);
    },
    valueFormatter: (params) => params.value.formattedValue,
    renderCell: (params) => params.formattedValue,
    sortComparator: (v1: FormattedTimeValue, v2: FormattedTimeValue) => v1.rawValue - v2.rawValue,
    width: isSmallScreen ? 150 : 190,
  };
}


class FormattedTimeValue {
  constructor(public formattedValue: string, public rawValue: number) { }
  toString() {
    return this.formattedValue;
  }
}