import { FC } from "react";
import { useSelector } from "react-redux";
import { TagTimeValidity } from "./models/TagsModels";
import classes from "./TableDataPoint.module.css";
import TagInvalidIcon from "./TagInvalidIcon";
import DataPointTooltip from "./wrapper/DataPointTooltip";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { RootState } from "../app/Store";
import {
  selectSiteTag,
  selectSiteTagDataStr,
} from "../app/store/MultisiteTagsStore";
import { selectSiteTagMetaData } from "../app/store/TagsMetadataStore";

interface ITableDataPointProps {
  tagName: string;
  titleOverride?: string;
  nameOverride?: string;
  siteId: string;
}

const TableDataPoint: FC<ITableDataPointProps> = ({
  tagName,
  titleOverride = null,
  nameOverride,
  siteId,
}) => {
  const tagValuesStr = useSelector((state: RootState) =>
    selectSiteTagDataStr(state, siteId, tagName)
  );
  const tag = useSelector((state: RootState) =>
    selectSiteTag(state, siteId, tagName)
  );
  const tagMetaData = useSelector((state: RootState) =>
    selectSiteTagMetaData(state.multiSitesTagsMetadata, siteId, tagName)
  );
  const title = titleOverride ? titleOverride : tagMetaData.displayName;
  const unit = tagMetaData.unitName;
  const isTagValid = tag.timeValidity != TagTimeValidity.Invalid;

  return (
    <DataPointTooltip
      displayName={nameOverride}
      isShowDateOnly={false}
      isShowTagMetadataOnly={false}
      tagName={tagName}
      siteId={siteId}
    >
      <div className={classes["table-data-point-container"]}>
        <div className={`${TextEnum.h4} ${classes.header}`}>
          <TagInvalidIcon tagName={tagName} siteId={siteId} />
          <div className={`${classes["title"]} ${TextEnum.h5}`}>{title}</div>
        </div>
        <div className={classes["table-data-point-body"]}>
          {tag.valueExist && isTagValid && (
            <>
              <span className={`${classes["value"]} ${TextEnum.h1}`}>
                {tagValuesStr.value}
              </span>
              <span className={`${classes["unit"]} ${TextEnum.h7}`}>
                {unit}
              </span>
            </>
          )}
          {!isTagValid && (
            <span className={`${TextEnum.h1}`}>{NO_VALUE_PLACEHOLDER}</span>
          )}
          {!tag.valueExist && isTagValid == false && (
            <div>{NO_VALUE_PLACEHOLDER}</div>
          )}
        </div>
      </div>
    </DataPointTooltip>
  );
};

export default TableDataPoint;
