export class DateTimeFormatter {
  private dateTimeFormatter: Intl.DateTimeFormat;
  private dateFormatter: Intl.DateTimeFormat;

  public constructor(timeZone: string) {
    this.dateTimeFormatter = Intl.DateTimeFormat("en-IL", {
      timeZone: timeZone,
      // weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    this.dateFormatter = Intl.DateTimeFormat("en-IL", {
      timeZone: timeZone,
      year: "numeric",
      month: "short",
      day: "2-digit"
    });
  }

  public formatDateAndTime(unixEpoch: number) {
    return this.dateTimeFormatter.format(unixEpoch).replaceAll(",", "");
  }

  public formatDate(unixEpoch: number) {
    return this.dateFormatter.format(unixEpoch);
  }
}

export default DateTimeFormatter;