import { GridColDef } from "@mui/x-data-grid";
import { humanizeEnumValue } from "../../../common/EnumUtils";
import { ComponentType, UrgencyLevel } from "../DTOs";
import { AlertUnion, IAlertMetadataMap } from "../Models";
import UrgencyCell from "./tableComponent/UrgencyCell";
import { NO_VALUE_PLACEHOLDER } from "../../../common/ConstantValues";
import { AlertDescriptionCell } from "./tableComponent/DescriptionCell";
import { TrackerNamesMap } from "../../sites/SiteModels";
import { tryGetTrackerName } from "../../trackers/TrackerName";
import classes from './ActiveAlertTableColumns.module.css'
import trackerNameClasses from '../../trackers/TrackerName.module.css'
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import DateTimeFormatter from "../../app/DateTimeFormatter";
import { formatAlertDescription } from "../Utils";
import HeaderWithIcon from "./tableComponent/HeaderWithIcon";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { createTimeCell } from "../../../common/table/TimeCellFactory";

const getActiveAlertTableColumns = (alertsMetadata: IAlertMetadataMap | undefined, trackerNamesMap: TrackerNamesMap, dtFormatter: DateTimeFormatter, is150PercentScreen: boolean
): GridColDef<AlertUnion>[] => [
    {
      field: "name",
      headerName: "Name",
      flex: 0.8,
      valueGetter: (params) => {
        if (params.row.kind === undefined || alertsMetadata === undefined) {
          return "";
        }
        return alertsMetadata[params.row.alertType].name;
      }
    },
    {
      field: "componentType",
      headerName: "Component",
      width: is150PercentScreen ? 150 : 170,
      valueGetter: (params) => {
        if (params.row.kind === undefined || alertsMetadata === undefined) {
          return "";
        }
        const componentType = alertsMetadata[params.row.alertType].componentType;
        return humanizeEnumValue(ComponentType[componentType]) ?? NO_VALUE_PLACEHOLDER;
      },
    },
    {
      field: "componentIds",
      headerName: "IDs",
      flex: 0.5,
      valueGetter: (params) => {
        if (params.row.kind === undefined || alertsMetadata === undefined) {
          return "";
        }
        const componentType = alertsMetadata[params.row.alertType].componentType;
        if (componentType === ComponentType.System) {
          return "--";
        }
        if (params.row.kind === "aggregated") {
          if (componentType === ComponentType.Tracker) {
            return params.row.deviceIds.map(x => tryGetTrackerName(x, trackerNamesMap)).join(", ");
          }
          return params.row.deviceIds.join(", ");
        }
        if (componentType === ComponentType.Tracker) {
          return tryGetTrackerName(params.row.deviceId, trackerNamesMap);
        }
        return params.row.deviceId;
      },
      renderCell: (params) => {
        if (params.row.kind === undefined || alertsMetadata === undefined) {
          return "";
        }
        const isTrackerComponent = alertsMetadata[params.row.alertType].componentType === ComponentType.Tracker;
        const extraClassName = isTrackerComponent ? trackerNameClasses["tracker-name"] : "";
        return <LightTooltipOnEllipsis
          title={params.value}
          customTooltipSx={{ padding: "1.2em", fontSize: 14 }}
        >
          <div className={classes['component-ids-column']}>
            <span className={extraClassName}>{params.value}</span>
          </div>
        </LightTooltipOnEllipsis>;
      }
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => alertsMetadata && <AlertDescriptionCell alert={params.row} metadata={alertsMetadata[params.row.alertType]} />,
      valueGetter: (params) => (alertsMetadata !== undefined && params.row.kind !== undefined)
        ? formatAlertDescription(params.row, alertsMetadata[params.row.alertType], trackerNamesMap)
        : '',
    },
    {
      field: "urgencyLevel",
      headerName: "Urgency",
      valueGetter: (params) => UrgencyLevel[params.row.urgencyLevel],
      renderCell: (params) => <UrgencyCell urgencyLevel={params.row.urgencyLevel} />,
      width: is150PercentScreen ? 100 : 120,
    },
    {
      ...createTimeCell(is150PercentScreen, dtFormatter, (row) => row.startTime),
      field: "startTime",
      renderHeader: () => <HeaderWithIcon label={"Opened"} icon={getIcons(FieldIcons.Calender, IconCategory.Fields)} />,
    },
  ];
export default getActiveAlertTableColumns;


