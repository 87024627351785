import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import localStorage from "reduxjs-toolkit-persist/lib/storage";
import { multisiteTagsReducer } from "./store/MultisiteTagsStore";
import { sitesMetadataReducer } from "./store/SitesStore";
import { multiSitesVisualInfoReducer } from "./store/SiteVisualInfoStore";
import { multiSitesTagsChartReducer } from "./store/TagsChartStore";
import { multiSitesTagsMetadataReducer } from "./store/TagsMetadataStore";
import { multiSitesTrackersCommandsReducer } from "./store/TrackersCommandsStore";
import { multiSitesTrackersReducer } from "./store/TrackersStore";
import { multiSitesUserRecordsReducer } from "./store/UserRecordsStore";
import { pagesReducer } from "../../pages/PagesStore";
import { multiSitesFaultsReducer } from "./store/FaultsStore";
import { trendsReducer } from "../multisite_trends/TrendsStore";
import { siteReducer } from "../sites/SiteStore";
import { userReducer } from "../user/UserStore";
import { safeOverrideReducer } from "./store/SafeOverrideStore";
import { faultsPageReducer } from "./store/FaultsPageStore";
import featuresReducer from "../featureFlags/FeatureFlagsStore";

const rootPersistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["user"],
};

const appReducer = combineReducers({
  user: userReducer,
  pages: pagesReducer,
  trends: trendsReducer,
  multiSitesFaults: multiSitesFaultsReducer,
  multiSitesUserRecords: multiSitesUserRecordsReducer,
  multiSitesTrackersCommands: multiSitesTrackersCommandsReducer,
  site: siteReducer,
  multiSitesMetadata: sitesMetadataReducer,
  multiSitesVisualInfo: multiSitesVisualInfoReducer,
  multiSitesTags: multisiteTagsReducer,
  multiSitesTagsMetadata: multiSitesTagsMetadataReducer,
  multiSitesChartsData: multiSitesTagsChartReducer,
  multiSitesTrackers: multiSitesTrackersReducer,
  safeOverride: safeOverrideReducer,
  faultsPage: faultsPageReducer,
  featureFlags: featuresReducer,
});

const persistedReducer: typeof appReducer = persistReducer(
  rootPersistConfig,
  appReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
