import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ISiteUIRowData, ITagDataWithMetaUnit } from "./MultiSiteModel";
import MultiSiteTableUi from "./MultiSiteTableUi";
import { repetitivePolling } from "../../common/AsyncUtils";
import APP_CONFIG from "../app/configuration/AppConfig";
import { AppDispatch, RootState } from "../app/Store";
import { multisiteTagsSlice } from "../app/store/MultisiteTagsStore";
import { getMultiSiteSpecificTagsLatest } from "../app/TagsAPI";
import { ISiteToTagList, TagSource } from "../data_point/models/TagsModels";
import TagsNames from "../data_point/TagsNames";
import { fetchActiveIssuesCounters, LoadingState } from "../app/store/FaultsStore";

const tagNameForTable = [
  TagsNames.SITE_AC_CURRENT_POWER,
  TagsNames.MCS_FIELD_STATE,
  TagsNames.WIND,
  TagsNames.DAILY_SP_PRODUCTION,
  TagsNames.GHI,
  TagsNames.DAILY_SP_EFFICIENCY,
  TagsNames.MOMENT_POTENTIAL_POWER,
  TagsNames.AGGR_INVERTERS_STATUS,
  TagsNames.AGGR_TRACKERS_STATUS,
  TagsNames.INVERTER_ERROR_COUNT,
  TagsNames.INVERTER_WARN_COUNT,
  TagsNames.WEATHER_ERROR_COUNT,
  TagsNames.WEATHER_WARN_COUNT,
];

const MultiSitesTable = () => {
  const siteIds = useSelector((state: RootState) => state.user.siteIds);
  const tagsMetadata = useSelector(
    (state: RootState) => state.multiSitesTagsMetadata
  );
  const tags = useSelector(
    (state: RootState) => state.multiSitesTags.tagsCache
  );
  const issueCounters = useSelector(
    (state: RootState) => state.multiSitesFaults.issueCounters
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (siteIds.length === 0) {
      return;
    }

    const cntrlr = new AbortController();
    if (Object.keys(tagsMetadata).length === 0) {
      return;
    }
    const getAllSitesCalculatedTagsAsync = async (signal: AbortSignal) => {
      const latestTags = await getMultiSiteSpecificTagsLatest(
        sitesWithCalculatedTags
      );
      if (signal.aborted) {
        return;
      }
      dispatch(multisiteTagsSlice.actions.updateMultiSiteTags(latestTags));
    };
    const getAllSitesStorageTagsAsync = async (signal: AbortSignal) => {
      const latestTags = await getMultiSiteSpecificTagsLatest(
        sitesWithStorageTags
      );
      if (signal.aborted) {
        return;
      }
      dispatch(multisiteTagsSlice.actions.updateMultiSiteTags(latestTags));
    };
    const [sitesWithStorageTags, sitesWithCalculatedTags] =
      groupTagsBySiteAndType();

    repetitivePolling(
      "getAllSitesCalculatedTagsAsync",
      getAllSitesCalculatedTagsAsync,
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );

    repetitivePolling(
      "getAllSitesStorageTagsAsync",
      getAllSitesStorageTagsAsync,
      APP_CONFIG.milliSecBetweenGetTags,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );

    repetitivePolling(
      "getActiveIssuesForAllSites",
      getActiveIssuesCountersForAllSites,
      APP_CONFIG.milliSecBetweenGetIssues,
      APP_CONFIG.milliSecBetweenFailedRequests,
      cntrlr.signal
    );

    return () => {
      cntrlr.abort();
    };
  }, [siteIds, tagsMetadata]);

  const getActiveIssuesCountersForAllSites = (signal: AbortSignal) =>
    new Promise<void>((resolve, reject) => {
      const dispatched = dispatch(fetchActiveIssuesCounters({ resolve, reject }));
      signal.onabort = () => dispatched.abort();      
    });

  const segregateTagsForSite = (siteId: string) => {
    const storageTags: string[] = [];
    const calculatedTags: string[] = [];

    tagNameForTable.forEach((tag) => {
      if (tagsMetadata[siteId] && tag in tagsMetadata[siteId]) {
        if (tagsMetadata[siteId][tag].tagSource === TagSource.Storage) {
          storageTags.push(tag);
        } else if (
          tagsMetadata[siteId][tag].tagSource === TagSource.Calculated
        ) {
          calculatedTags.push(tag);
        }
      }
    });

    return { storageTags, calculatedTags };
  };

  const groupTagsBySiteAndType = () => {
    const sitesWithStorageTags: ISiteToTagList = {};
    const sitesWithCalculatedTags: ISiteToTagList = {};
    siteIds.forEach((siteId: string) => {
      const { storageTags, calculatedTags } = segregateTagsForSite(siteId);

      if (storageTags.length) {
        sitesWithStorageTags[siteId] = storageTags;
      }
      if (calculatedTags.length) {
        sitesWithCalculatedTags[siteId] = calculatedTags;
      }
    });
    return [sitesWithStorageTags, sitesWithCalculatedTags];
  };

  const createRowItem = (siteId: string) => {
    const tableItem: ISiteUIRowData = { siteId };
    if (!tags || !tagsMetadata) {
      return tableItem;
    }
    const specificSiteMetadata = tagsMetadata[siteId];
    const specificTagsData = tags[siteId];
    if (specificSiteMetadata && specificTagsData) {
      tagNameForTable.forEach((tag: string) => {
        const tagData = specificTagsData[tag];
        const tagMetaData = specificSiteMetadata[tag];
        if (tagMetaData && tagData) {
          const tagItem: ITagDataWithMetaUnit = {
            value: tagData.value,
            valueAsStr: tagMetaData.toStringConverter(tagData.value),
            unit: tagMetaData.unitName,
            time: tagData.time,
            valueExist: true,
            timeValidity: tagData.timeValidity,
          };
          tableItem[tag] = tagItem;
        }
      });
    }
    if (issueCounters.loading == LoadingState.Complete &&
      Object.hasOwn(issueCounters.data, siteId)) {
      const siteIssuesData = issueCounters.data[siteId];
      tableItem.amountOfIssuesLowSeverity = siteIssuesData.lowSeverity;
      tableItem.amountOfIssuesMildSeverity = siteIssuesData.mediumSeverity;
      tableItem.amountOfIssuesHighSeverity = siteIssuesData.highSeverity;
    }
    return tableItem;
  };

  const data = siteIds.map(createRowItem);
  return <MultiSiteTableUi data={data} />;
};

export default MultiSitesTable;
