import { GridColDef } from "@mui/x-data-grid";

import { UserRecordDeleteCellItem } from "./UserRecordDeleteCellItem";
import TableCellItemBool from "../../../common/table/TableCellItemBool";
import TableCellItemDate from "../../../common/table/TableCellItemDate";
import TableCellItemString from "../../../common/table/TableCellItemString";
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import {
  UNDEFINED_STR_FOR_DATE_TIME,
  formatDateToDayMonthYearHourMinuteSeconds,
  toDateInSiteTimeZone,
} from "../../app/DateTimeUtils";
import { ComponentEnum } from "../UserRecordsModels";
import trackerNameClasses from '../../trackers/TrackerName.module.css';
import cellItemClasses from './UserRecordsCellItem.module.css';

export const userRecordsColumsNames = {
  Title: "title",
  Date: "eventDate",
  Reporter: "reporter",
  Component: "componentType",
  ComponentId: "componentName",
  RecordType: "recordType",
  Description: "description",
  Id: "id",
  IsEngineerOnly: "isEngineerOnly",
};

export const createUserRecordsTablesColumns = (
  isEngineer: boolean,
  siteTimeZoneOffsetInMinutes: number
): GridColDef[] => {
  const userRecordsTableColumns: GridColDef[] = [
    {
      field: userRecordsColumsNames.Title,
      headerName: "Title",
      flex: 1,
      renderCell: (params) => <TableCellItemString value={params.value} />,
    },
    {
      field: userRecordsColumsNames.Date,
      headerName: "Date",
      flex: 1,
      valueFormatter: (date) => {
        return formatDate(date.value as string, siteTimeZoneOffsetInMinutes);
      },
      renderCell: (params) => <TableCellItemDate value={params.value} />,
    },
    {
      field: userRecordsColumsNames.Reporter,
      headerName: "Reporter",
      flex: 1,
      renderCell: (params) => <TableCellItemString value={params.value} />,
    },
    {
      field: userRecordsColumsNames.Component,
      headerName: "Component",
      flex: 1,
      renderCell: (params) => <TableCellItemString value={params.value} />,
    },
    {
      field: userRecordsColumsNames.ComponentId,
      headerName: "ComponentId",
      flex: 1,
      renderCell: (params) => {
        const isTrackerComponent = params.row.componentType === ComponentEnum[ComponentEnum.Tracker];
        const extraClassName = cellItemClasses['ellipsis'] + ' ' + (isTrackerComponent ? trackerNameClasses['tracker-name'] : '');
        return <LightTooltipOnEllipsis title={params.value}>
          <div style={{ width: '100%' }}>
            <TableCellItemString value={params.value} extraClassName={extraClassName} />
          </div>
        </LightTooltipOnEllipsis>
      },
    },
    {
      field: userRecordsColumsNames.RecordType,
      headerName: "RecordType",
      flex: 1,
      renderCell: (params) => <TableCellItemString value={params.value} />,
    },
    {
      field: userRecordsColumsNames.Description,
      headerName: "Description",
      flex: 1,
      renderCell: (params) => <TableCellItemString value={params.value} />,
    },
    {
      field: userRecordsColumsNames.Id,
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: UserRecordDeleteCellItem,
      disableExport: true,
    },
  ];

  if (isEngineer) {
    userRecordsTableColumns.splice(7, 0, {
      field: userRecordsColumsNames.IsEngineerOnly,
      headerName: "Engineer Only",
      flex: 1,
      renderCell: (params) => <TableCellItemBool value={params.value} />,
    });
  }
  return userRecordsTableColumns;
};

const formatDate = (dateVal: string, siteTimeOffset: number) => {
  const siteDate = dateVal
    ? toDateInSiteTimeZone(new Date(dateVal), siteTimeOffset)
    : null;
  const formatedDate = siteDate
    ? formatDateToDayMonthYearHourMinuteSeconds(siteDate)
    : UNDEFINED_STR_FOR_DATE_TIME;
  return formatedDate;
};
