import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddingNewUserRecordUi from "./AddingNewUserRecordUi";
import { addNewUserRecord } from "./UserRecordsAPI";
import { IUserRecord, IUserRecordDto } from "./UserRecordsModels";
import { fromZonetimeDateToUtcTime } from "../app/DateTimeUtils";
import { RootState } from "../app/Store";
import { multiSitesUserRecordsSlice } from "../app/store/UserRecordsStore";
import { selectSiteId } from "../sites/SiteStore";

interface IAddingNewUserRecordProps {
  onAddedNewRecord: () => void;
}

const AddingNewUserRecord: FC<IAddingNewUserRecordProps> = ({
  onAddedNewRecord,
}) => {
  const dispatch = useDispatch();
  const siteTimeOffset = useSelector((state: RootState) => state.site.timeZoneOffsetInMinutes);
  const siteId = useSelector(selectSiteId);
  const addRecord = async (values: IUserRecord) => {
    const dateInSiteTime = fromZonetimeDateToUtcTime(
      new Date(values.eventDate),
      siteTimeOffset
    ).getTime();
    const newRecord: IUserRecordDto = {
      ComponentType: values.componentType,
      EventDate: dateInSiteTime,
      ComponentName: values.componentName,
      Description: values.description,
      RecordType: values.recordType,
      Reporter: values.reporter,
      Title: values.title,
      IsEngineerOnly: values.isEngineerOnly
    };
    const addedRecord = await addNewUserRecord(siteId, newRecord);
    dispatch(
      multiSitesUserRecordsSlice.actions.addRecord({
        site: siteId,
        record: addedRecord,
      })
    );
    onAddedNewRecord();
  };
  return (
    <AddingNewUserRecordUi
      addRecord={addRecord}
      siteTimeOffset={siteTimeOffset}
    />
  );
};
export default AddingNewUserRecord;
