import { addMinutes, format } from "date-fns";
//Get clock in the site time
export function getCurrentDateInSiteTimeZone(
  zoneOffsetTimeInMinutes: number
): Date {
  const date = new Date();
  const tzDifference = date.getTimezoneOffset() + zoneOffsetTimeInMinutes;
  const zonedTime = addMinutes(date, tzDifference);
  return zonedTime;
}

//Send UTC, get the same hour in another site
export function fromUtcToSiteTime(
  utcTimeInLocalMissingShift: Date,
  zoneOffsetTimeInMinutes: number
) {
  const zonedTime = addMinutes(
    utcTimeInLocalMissingShift,
    -zoneOffsetTimeInMinutes
  );
  return zonedTime;
}

export function fromLocalToUtcTime(localTime: Date) {
  const utcTime = addMinutes(localTime, localTime.getTimezoneOffset());
  return utcTime;
}

export function getDayEndInUtcFromDateOnly(zonedDateTime: Date): Date {
  const year = zonedDateTime.getFullYear();
  const month = zonedDateTime.getMonth();
  const day = zonedDateTime.getDate();
  const hours = 20;
  const minutes = 0;
  const seconds = 0;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function getDayStartInUtcFromDateOnly(dateOnly: Date): Date {
  const year = dateOnly.getFullYear();
  const month = dateOnly.getMonth();
  const day = dateOnly.getDate();
  const hours = 4;
  const minutes = 0;
  const seconds = 0;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function getDayStartInUtcFromDateOnlyForMoreThanOneDay(
  dateOnly: Date
): Date {
  const year = dateOnly.getFullYear();
  const month = dateOnly.getMonth();
  const day = dateOnly.getDate();
  const hours = 12;
  const minutes = 0;
  const seconds = 0;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function getMidnightInUtcFromDateOnly(zonedDateTime: Date): Date {
  const year = zonedDateTime.getFullYear();
  const month = zonedDateTime.getMonth();
  const day = zonedDateTime.getDate();
  const hours = 23;
  const minutes = 59;
  const seconds = 59;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

// beginning of start day is 00:00:00
export function getBeginningOfStartInUtcFromDateOnly(dateOnly: Date): Date {
  const year = dateOnly.getFullYear();
  const month = dateOnly.getMonth();
  const day = dateOnly.getDate();
  const hours = 0;
  const minutes = 0;
  const seconds = 0;
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function getEndDayFromUtc(
  dateInZonedTime: Date,
  zoneOffsetTimeInMinutes: number,
  endDayFunc: (date: Date) => Date
): Date {
  return fromUtcToSiteTime(
    endDayFunc(dateInZonedTime),
    zoneOffsetTimeInMinutes
  );
}

export function getStartDayFromUtc(
  dateInZonedTime: Date,
  zoneOffsetTimeInMinutes: number,
  startDayFunc: (date: Date) => Date
): Date {
  return fromUtcToSiteTime(
    startDayFunc(dateInZonedTime),
    zoneOffsetTimeInMinutes
  );
}

//Change the date to reflect the site by shifting the local time
///browser always saves the date with the local offset.
///takes the date with the local time offset, and returns the date with the site time offset
//Assumes Local time +2, timezone + 3 => date = 15:00
//This func will return 16:00
export function toDateInSiteTimeZone(
  utcTimeInLocalDateFormat: Date,
  zoneOffsetTimeInMinutes: number
) {
  const tzDifference =
    utcTimeInLocalDateFormat.getTimezoneOffset() + zoneOffsetTimeInMinutes;
  const zonedTime = addMinutes(utcTimeInLocalDateFormat, tzDifference);
  return zonedTime;
}
//User chose 10:00 in datepicker, means he wants 10:00 according to the zone.
//Assume Local=+2, Zone= +5.5
//The formula = DatePicker +localOffset - ZoneTime
//The result should be 6:30 local trime (getTime will return 4:30)
//Note getTimezoneOffset is the inverse value of zone offset from utc
//10+2-5.5 = 6.5
export function fromDatePickerToUtc(
  datePickerTimeAsZonedTime: Date,
  zoneOffsetTimeInMinutes: number
): Date {
  const tzDifference =
    -datePickerTimeAsZonedTime.getTimezoneOffset() - zoneOffsetTimeInMinutes;
  const zonedTime = addMinutes(datePickerTimeAsZonedTime, tzDifference);
  return zonedTime;
}

export function fromZonetimeDateToUtcTime(
  localTimeThatShouldBeZoneTime: Date,
  zoneOffsetTimeInMinutes: number
) {
  const tzDifference =
    localTimeThatShouldBeZoneTime.getTimezoneOffset() - zoneOffsetTimeInMinutes;

  const zonedTime = addMinutes(localTimeThatShouldBeZoneTime, tzDifference);
  return zonedTime;
}

export function dateTimeToString(date: number | Date): string {
  return format(date, YEAR_TO_SECOND_FORMAT);
}

export const convertStringToSeconds = (intervalString: string) => {
  const decimalBase = 10;
  const array = intervalString.split(":");
  return (
    parseInt(array[0], decimalBase) * 60 * 60 +
    parseInt(array[1], decimalBase) * 60 +
    parseInt(array[2], decimalBase)
  );
};
//example Sun 1 Jan
export const formatDateToWeekdayDayMonth = (date: Date) => {
  return date
    .toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })
    .replace(",", "");
};
//example  1 Jan 2021
export const formatDateToDayMonthYear = (date: Date) => {
  return date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(",", "");
};
//HH:mm:ss
export const formatTime = (date: Date) => {
  return date.toLocaleString("en-GB", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
};
//HH:MM
export const formatTimeNoSeconds = (date: Date) => {
  return date.toLocaleString("en-GB", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};
//This is the default time of the project! example Sun 1 Jan 00:00:00
export const formatDateTimeNoYear = (value: Date) => {
  const formattedDate = formatDateToWeekdayDayMonth(value);
  const formattedTime = formatTime(value);

  return `${formattedDate} ${formattedTime}`;
};
//example 1 Jan 2021 00:00:00
export const formatDateToDayMonthYearHourMinuteSeconds = (value: Date) => {
  const formattedDate = formatDateToDayMonthYear(value);
  const formattedTime = formatTime(value);

  return `${formattedDate} ${formattedTime}`;
};
//example 1 Jan 2021 00:00
export const formatDateTimeToDayMonthYearHourMinutes = (value: Date) => {
  const formattedDate = formatDateToDayMonthYear(value);
  const formattedTime = formatTimeNoSeconds(value);

  return `${formattedDate} ${formattedTime}`;
};
export function changeYearInDateToUpperCase(date: string) {
  return date.replace("yyyy", "YYYY");
}

export const stringifyDateTimeWithoutTimeZone = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month index
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
export const UNDEFINED_STR_FOR_TIME = "--:--:--";
export const UNDEFINED_STR_FOR_DATE = "-- -- ----";
export const UNDEFINED_STR_FOR_DATE_TIME =
  UNDEFINED_STR_FOR_DATE + " " + UNDEFINED_STR_FOR_TIME;

export const YEAR_TO_SECOND_FORMAT = "dd MMM yyyy HH:mm:ss";
export const YEAR_TO_MINUTE_FORMAT = "dd MMM yyyy HH:mm";
export const YEAR_TO_DAY_FORMAT = "dd MMM yyyy";

export const MONTH_TO_MINUTE_FORMAT = "MMM dd HH:mm";
export const MONTH_TO_DAY_FORMAT = "MMM dd";
export const HOUR_TO_MINUTE_FORMAT = "HH:mm";
