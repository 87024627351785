import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISiteMetadata } from "./SiteModels";
import { RootState } from "../app/Store";
import { DateTimeFormatter } from "../app/DateTimeFormatter";
import { resetSiteSpecificData } from "../app/store/GlobalActions";

export const defaultProperties: ISiteMetadata = {
  siteId: "",
  timeZoneOffsetInMinutes: 0,
  trackerIds: [],
  weatherDeviceIds: [],
  inverterIds: [],
  powerMeterIds: [],
  ianaTimeZoneName: "",
  upsExists: false,
  nrtImageUrl: "",
  cloudTypeImageUrl: ""
};
export const siteSlice = createSlice({
  name: "site",
  initialState: defaultProperties,
  reducers: {
    setSiteMetadata: (
      state: ISiteMetadata,
      action: PayloadAction<ISiteMetadata>
    ) => {
      return { ...state, ...action.payload };
    },
    resetSite: (state: ISiteMetadata) => {
      return { ...state, ...defaultProperties };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSiteSpecificData, () => {
      return defaultProperties;
    });
  },
});

export const siteReducer = siteSlice.reducer;

export const selectSiteId = createSelector(
  (state: RootState) => state.site,
  (siteSlice) => siteSlice.siteId
);

export const selectSiteTrackersMap = createSelector(
  (state: RootState) => state.site,
  (siteSlice) => siteSlice.trackerNamesMap ?? {}
);

export const selectSiteDateTimeFormatter = createSelector(
  (state: RootState) => state.site,
  (siteSlice) =>
    new DateTimeFormatter(siteSlice.ianaTimeZoneName)
);
