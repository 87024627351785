import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMultipleTagsMetaData } from "../app/TagsAPI";
import { fetchFaultsMetadata, LoadingState } from "../app/store/FaultsStore";
import { multiSitesVisualInfoSlice } from "../app/store/SiteVisualInfoStore";
import { sitesMetadataSlice } from "../app/store/SitesStore";
import { multiTagsMetadataSlice } from "../app/store/TagsMetadataStore";
import { getSitesVisualInformation } from "../site_visual_info/SiteVisualInfoApi";
import { getSitesMetadataAsync } from "../sites/SiteAPI";
import { AppDispatch, RootState } from "../app/Store";


const useSetupTagsInfo = ({ siteIds }: { siteIds: string[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingFaultsMetadata: LoadingState = useSelector((state: RootState) => state.multiSitesFaults.metadata.loading);
  const [isFetchingData, setIsFetchingData] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const fetchTasks = [];
      fetchTasks.push(
        getMultipleTagsMetaData(siteIds).then((tagsMetaDataResponse) => {
          dispatch(
            multiTagsMetadataSlice.actions.setTagsMetaData(tagsMetaDataResponse)
          );
        })
      );
      fetchTasks.push(
        getSitesMetadataAsync(siteIds).then((siteMetaData) => {
          dispatch(sitesMetadataSlice.actions.setSitesMetadata(siteMetaData));
        })
      );
      fetchTasks.push(
        getSitesVisualInformation(siteIds).then((sitesVisualInformation) => {
          dispatch(
            multiSitesVisualInfoSlice.actions.setSitesVisualInfo(
              sitesVisualInformation
            )
          );
        })
      );
      try {
        await Promise.all(fetchTasks);
      } catch (e) {
        setError(e);
      }
      setIsFetchingData(false);
    };

    if (siteIds.length === 0) {
      return;
    }
    dispatch(fetchFaultsMetadata());
    fetchData();
  }, [siteIds]);

  useEffect(() => {
    if (loadingFaultsMetadata === LoadingState.Error) {
      setError("Failed to load faults metadata");
    }
  }, [loadingFaultsMetadata]);

  return { isLoading: isFetchingData || loadingFaultsMetadata === LoadingState.Pending, error };
};
export default useSetupTagsInfo;
