import { FC, useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { AlertTransition, IAlertMetadata } from "../../DTOs";
import { AlertUnion } from "../../Models";
import { dialogStyling } from "../../../../common/Mui/MuiStyling";
import { mapSverityToIcons, formatAlertDescription } from "../../Utils";
import { selectSiteDateTimeFormatter, selectSiteId, selectSiteTrackersMap } from "../../../sites/SiteStore";
import Table from "../../../../common/table/Table";
import { LoadingState } from "../../../app/store/FaultsStore";
import { getAlertTransitions } from "../../API";
import { useIs150PercentScreen } from "../../../../common/WindowUtils";
import { normalizeTransitions } from "./Utils";
import { getTableColumns } from "./TableColumns";
import classes from "./AlertDetailsDialog.module.css";

interface IAlertDetailsDialogProps {
    alert: AlertUnion | undefined;
    metadata: IAlertMetadata | undefined;
    onClose: () => void;
    open: boolean
}

const AlertDetailsDialog: FC<IAlertDetailsDialogProps> = ({ alert, open, metadata, onClose }) => {
    const trackerNamesMap = useSelector(selectSiteTrackersMap);
    const siteId = useSelector(selectSiteId);
    const [transitions, setTransitions] = useState<(AlertTransition & { id: number })[] | undefined>();
    const [transitionsLoadingState, setTransitionsLoadingState] = useState<LoadingState>(LoadingState.Pending);
    const siteTimeFormatter = useSelector(selectSiteDateTimeFormatter);
    const is150PercentScreen = useIs150PercentScreen();

    useEffect(() => {
        if (!alert) {
            return;
        }
        const abortController = new AbortController();
        const fetchTransitions = async () => {
            setTransitionsLoadingState(LoadingState.Pending);
            try {
                const transitions = await getAlertTransitions(siteId, alert.kind, alert.id, abortController.signal);
                const transitionsNormalized = normalizeTransitions(alert, transitions);
                setTransitions(transitionsNormalized);
                setTransitionsLoadingState(LoadingState.Complete);
            } catch {
                if (abortController.signal.aborted) {
                    return;
                }
                setTransitionsLoadingState(LoadingState.Error);
            }
        }
        fetchTransitions();
        return () => {
            abortController.abort();
        }
    }, [alert, metadata, open]);

    const columnsDef = useMemo(() => {
        if (!alert || !metadata) {
            return null;
        }
        return getTableColumns(alert, metadata, siteTimeFormatter, trackerNamesMap, is150PercentScreen);
    },
        [alert, metadata, siteTimeFormatter, trackerNamesMap, is150PercentScreen]
    );

    if (!alert || !metadata || !columnsDef) {
        return <></>;
    }

    const UrgencyLevelIcon = mapSverityToIcons(alert.urgencyLevel);

    return <Dialog open={open} onClose={onClose} sx={dialogStyling} maxWidth="xl">
        <DialogTitle className={classes["dialog-title"]}>
            <UrgencyLevelIcon width={24} /> {metadata.name} - Details
        </DialogTitle>
        <DialogContent className={classes["dialog-content-container"]}>
            <div className={classes["alert-properties"]}>
                <div className={classes["alert-property"]}>
                    <div className={classes["alert-property-label"]}>
                        Time Opened:
                    </div>
                    <div className={classes["alert-property-value"]}>
                        {siteTimeFormatter.formatDateAndTime(alert.startTime)}
                    </div>
                </div>
                <div className={classes["alert-property"]}>
                    <div className={classes["alert-property-label"]}>
                        Time Resolved:
                    </div>
                    <div className={classes["alert-property-value"]}>
                        {(alert.history && alert.endTime) ? siteTimeFormatter.formatDateAndTime(alert.endTime) : "N/A"}
                    </div>
                </div>
                <div className={classes["alert-property"]}>
                    <div className={classes["alert-property-label"]}>
                        Alert Reason:
                    </div>
                    <div className={classes["alert-property-value"]}>
                        {formatAlertDescription(alert, metadata, trackerNamesMap)}
                    </div>
                </div>
            </div>
            <div className={classes["transitions-table-header"]}>
                Alert History
                <div className={classes["transitions-table-header-underline"]}></div>
            </div>
            <div className={classes["transitions-table-container"]}>
                <Table
                    hideToolbar
                    hideFooterPagination
                    initialState={{
                        sorting: {
                          sortModel: [{ field: "transitionTime", sort: "desc" }],
                        },
                      }}
                    loading={transitionsLoadingState === LoadingState.Pending}
                    rows={transitions || []}
                    columns={columnsDef}
                    noRowsMessage={transitionsLoadingState === LoadingState.Error ? "Failed to load alert history!" : "No alert history"}
                    customUI={{
                        "& .MuiDataGrid-main": {
                            border: "1px solid #E9E9E9",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            margin: "1px 4px"
                        },
                    }}
                    getRowId={(transition) => transition.id || ""}
                    rowHeight={45}
                    columnHeaderHeight={45}
                    rowSelection={false}
                />
            </div>
        </DialogContent>
    </Dialog >
};
export default AlertDetailsDialog;
